import React from 'react'
import { withRouter } from "react-router-dom"
import { 
  Button,
  Carousel
} from 'antd'
import {
  imageConstant,
  homeCatalogList
} from "@json"
import './styles.less'

const HomePage = ({ history }) => {
  return (
    <div className="homePage">
      <div className="container">
        <div className="headSlogan">
          <img src={imageConstant.headSlogan} alt="" className="image onlyVisibleInMobile" />
          <div className="desc_container">
            <p className="title">Welcome to Gacha.Cards!</p>
            <p className="content">Having fun with NFT blind boxes.</p>
            <Button type="primary" className="claimButn" onClick={() => {
              history.push({pathname: "/collection/happy-monkey"})
            }}>Claim Now<div className="arrowLine" /><div className="arrowRight" /></Button>
          </div>
          <img src={imageConstant.headSlogan} alt="" className="image onlyNoneInMobile" />
        </div>
        <div className="newsEvents">
          <p className="title">Top Collections</p>
          <Carousel className="carouselList">
          { 
            [imageConstant.homeBanner].map((data, idx) => (
              <img src={data} alt="" key={idx} className="item_container" />
            ))
          }
          </Carousel>
        </div>
        <div className="catalog">
          <p className="title">All Categories</p>
          <ul className="list">
          {
            homeCatalogList.map((data, index) => (
              <div key={index} className="listItem">
                <img src={data.url} alt="" className="image" />
                <div className="itemContainer" onClick={() => {
                  if (index === homeCatalogList.length - 1) {
                    return
                  } else {
                    history.push({pathname: data.pathname})
                  }
                }}>
                  <p className="name">{data.name}</p>
                  {
                    index !== homeCatalogList.length - 1 && <div className="enterButn"><div className="arrowLine" /><div className="arrowRight" /></div>
                  }
                  {
                    index === homeCatalogList.length - 1 && <p className="content">{data.pathname}</p>
                  }
                </div>
              </div>
            ))
          }
          </ul>
        </div>
      </div>
    </div>
  )
}
export default withRouter(HomePage)