import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import config from "./config"
import Provider from '@store'
import './index.less';

React.Component.prototype.$config = config;

ReactDOM.render(
  <Provider>
    <App />
 </Provider>,
  document.getElementById('root')
);

reportWebVitals();
