import React, {
  useState,
  useEffect
} from 'react'
import './styles.less'

const Tabs = (props) => {
  const [data, setData] = useState([])
  const [selectIndex, setSelectIndex] = useState(0)

  useEffect(() => {
    setData(props.data)
  },[props.data])

  useEffect(() => {
    setSelectIndex(props.index)
  },[props.index])

  return (
    <ul className="tabList">
    {
      data.map((item, index) => {
        return <li className={`tabItem ${selectIndex === index ? 'tabItemActive' : ''}`} key={index} onClick={() => {
          setSelectIndex(index)
          props.selectTabs(index)
        }}>
          <p className={`tabName ${selectIndex === index ? 'tabNameActive' : ''}`}>{item}</p>
          {/* <div className="tabLine" style={{visibility: selectIndex === index ? "visible" : "hidden"}} /> */}
        </li>
      })
    }
    </ul>
  )
}

export default Tabs