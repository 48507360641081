const reducer = (state, action) => {
  switch(action.key) {
    case "address":
      return { ...state, address: action.value }
    case "simpleAddress":
      return { ...state, simpleAddress: action.value }
    case "injectedProvider":
      return { ...state, injectedProvider: action.value }
    case "wrongNetwork":
      return { ...state, wrongNetwork: action.value }
    default:
      return state
  }
}

export default reducer