export const toGetCollectionId = (pathname) => {
  var names = pathname.split("/")
  if (names === undefined || names === null || names.length === 0) {
    return null;
  }
  var index = names.findIndex(item => item === "collection")
  if (index === -1) {
    return null;
  }
  if (index + 1 > names.length - 1) {
    return null;
  }
  return names[index + 1]
}
export const gcUseContractReader =  async (contracts, contractName, functionName, functionArgs = [], formatter) => {
  var _a
  const contractFunction = (_a = contracts === null || contracts === void 0 ? void 0 : contracts[contractName]) === null || _a === void 0 ? void 0 : _a[functionName]
  let newValue
  if (contractFunction) {
    if (functionArgs && functionArgs.length > 0) {
      newValue = await contractFunction(...functionArgs)
    }
    else {
      newValue = await contractFunction()
    }
    if (formatter && typeof formatter === 'function') {
      newValue = formatter(newValue)
    }
  }
  return newValue
}
export function getUrlParams(name, str) {
  const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
  const r = str.substr(1).match(reg);
  if (r != null) return  decodeURIComponent(r[2]); return null;
}
export function openUrl(url) {
  window.open(url)
}
export function getSimpleAddress(address) {
  const _address = address.toLowerCase()
  const simpleAddress = _address.substring(0,4) + "..." + _address.substring(_address.length-4, _address.length)
  return simpleAddress
}