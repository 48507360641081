import React, { 
  useState, 
  useCallback, 
  useEffect 
} from "react"
import { useLocation } from "react-router-dom"
import { ethers } from "ethers"
import { web3Modal } from "@contractConfig"
import { useUserProviderAndSigner } from "eth-hooks"
import { useConfigStore } from '@store'
import { 
  imageConstant,
  textConstant
} from "@json"
import { getSimpleAddress } from '@tool'
import { Button } from 'antd'
import "./styles.less"

const Head = () => {
  const location = useLocation()
  const { state, dispatch } = useConfigStore()
  const [injectedProvider, setInjectedProvider] = useState()

  const userProviderAndSigner = useUserProviderAndSigner(injectedProvider, null)
  const userSigner = userProviderAndSigner.signer

  useEffect(() => {
    async function getAddress() {
      if (userSigner) {
        const _address = await userSigner.getAddress()
        dispatch({key: "address", value: _address})
        const _simpleAddress = getSimpleAddress(_address)
        dispatch({key: "simpleAddress", value: _simpleAddress})
        const chainId = userSigner.provider._network.chainId
        if (textConstant.ChainId === chainId) {
          dispatch({key: "wrongNetwork", value: false})
        } else {
          dispatch({key: "wrongNetwork", value: true})
        }
      }
    }
    getAddress()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSigner])

  useEffect(() => {
    dispatch({key: "injectedProvider", value: injectedProvider})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [injectedProvider])

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect()
    setInjectedProvider(new ethers.providers.Web3Provider(provider))

    provider.on("chainChanged", async (chainId) => {
      setInjectedProvider(new ethers.providers.Web3Provider(provider))
    })

    provider.on("accountsChanged", async () => {
      setInjectedProvider(new ethers.providers.Web3Provider(provider))
    })
  }, [setInjectedProvider])

  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider()
    if (injectedProvider && injectedProvider.provider && typeof injectedProvider.provider.disconnect == "function") {
      await injectedProvider.provider.disconnect()
    }
    setTimeout(() => {
      window.location.reload()
    }, 1)
  }

  useEffect(() => {
    if (web3Modal.cachedProvider && location.pathname !== "/") {
      loadWeb3Modal()
    }
  // eslint-disable-next-line
  }, [loadWeb3Modal])

  return (
    <div className="head">
      <div className="container">
        <p className="title" onClick={() => {
          window.location.href = "/" 
        }}></p>
        <div className="containerR">
          { (!state.wrongNetwork && !state.address && location.pathname !== "/") && <Button type="primary" className="connectButn" onClick={loadWeb3Modal}>Connect Wallet</Button> }
          { (!state.wrongNetwork && state.address) && <Button type="primary" className="connected"  onClick={logoutOfWeb3Modal}>{state.simpleAddress}</Button> }
          { state.wrongNetwork && location.pathname !== "/" && <Button type="primary" className="networkError">Network Error</Button> }
          <img src={imageConstant.profile} alt="" className="profileButn" />
        </div>
      </div>
    </div>
  )
}
export default Head
