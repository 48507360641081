import React, {
  useState,
  useEffect
} from 'react'
import { withRouter } from "react-router-dom"
import { Button } from 'antd'
import { useContractLoader } from "eth-hooks"
import { useContractConfig } from "@contractConfig"
import { gcUseContractReader } from "@tool"
import { useConfigStore } from '@store'
import { imageConstant, textConstant } from "@json"
import { Tabs, BuyModal } from '@component'
// import { useTranslation } from "react-i18next"
import './styles.less'

const HappyMonkeyPage = ({ history }) => {
  // const { t } = useTranslation()
  const { state } = useConfigStore()
  const [account, setAccount] = useState(0)
  const [accounts, setAccounts] = useState([0])
  const [activeIndex, setActiveIndex] = useState(0)
  const [modal, setModal] = useState(false)

  const contractConfig = useContractConfig()
  const readContracts = useContractLoader(state.injectedProvider, contractConfig)

  console.log("readContracts:", readContracts)
  useEffect(() => {
    async function getAccount() {
      try {
        console.log("======")
        const _newAccount = await gcUseContractReader(readContracts, "HappyMonkeyNft", "blindBoxLength", [])
        console.log("_newAccount:", _newAccount)
        const _account = !_newAccount ? 0 : _newAccount - 11
        setAccount(_account)
      } catch (error) {
        console.log("error:", error)
      }
    }
    getAccount() 
  },[readContracts])

  useEffect(() => {
    async function getAccounts() {
      const _account = account.toString()
      const _accounts = _account.split("")
      setAccounts(_accounts)
    }
    getAccounts()
  }, [account])

  function onClickTab(index) {
    if (index === 0) {
      scrollToAnchor("SeriesContentId")
    } else {
      scrollToAnchor("DescriptionId")
    }
  }

  function scrollToAnchor(anchorName) {
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName)
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: 'smooth'})
      }
    }
  }

  useEffect(() => {
    let element = document.getElementById("tabContainerId")
    let tabSeriesElem = document.getElementById("SeriesContentId")
    element.addEventListener('scroll', () => {
      var offset_top = element.scrollTop
      if (offset_top < tabSeriesElem.offsetHeight) {
        setActiveIndex(0)
      } else if (offset_top >= tabSeriesElem.offsetHeight) {
        setActiveIndex(1)
      }
    })
  },[])

  return (
    <div className="HappyMonkeyPage">
      <div className="top-banner-div">
        <div className="container-top">
          <div className="empty-div"></div>
          <img src="https://nft-1.oss-cn-beijing.aliyuncs.com/GachaCards/topBanner01.png" alt="" className="top-banner" />
          <div className="empty-div"></div>
        </div>
        <img src="https://nft-1.oss-cn-beijing.aliyuncs.com/GachaCards/topBanner02.png" alt="" className="top-banner" />
      </div>
      <div className="container">
        <div className="headInfo">
          <img src={imageConstant.happyMonkeyBox} alt="" className="image" />
          <div className="infoContainer">
            <p className="name">HAPPY MONKEY</p>
            <div className="color-line"></div>
            <p className="remainingTxt">Remaining</p>
            <ul className="accountList">
            {
              accounts.map((item, index) => {
                return <li className="accountItem" key={index}>{item}</li>
              })
            }
            </ul>
            <div className="color-line"></div>
            <p className="priceTxt">Price</p>
            <p className="ethPrice">0<span className="ethPriceTxt">ETH</span></p>
            <p className="moneyPrice">≈ ＄0</p>
            { (!!account && account > 0) && <Button type="primary" block className="claimButn" onClick={() => { setModal(true) }}>Mint</Button> }
            { !account && <Button type="primary" disabled block className="claimButn" >Loading</Button> }
            <img src="https://nft-1.oss-cn-beijing.aliyuncs.com/GachaCards/download-icon.png" alt="" className="download-button" onClick={() => window.open("https://gacha.cards/mint/0xAc7f40Aa2D637A06Ec69BdaaA4F3277ff521D02F")} />
          </div>
        </div>
        <div className="content">
          <div className="tabs">
            <Tabs data={["Collection Content", "Description"]} index={activeIndex} selectTabs={(index) => {onClickTab(index)}} />
            <div className="tab-color-line"></div>
          </div>
          <div className="tabContainer" id="tabContainerId">
            <ul className="cardsList" id="SeriesContentId">
            {
              textConstant.HappyMonkeySeriesContent.map((item, index) => {
                return <li className="cardItem" key={index}>
                  <img src={`${imageConstant.happyMonkeyCard}${item.url}.jpg`} alt="" className="itemImage" />
                  <p className="itemName">{item.name}</p>
                  <div className="itemDescContainer">
                    <p className="descKey">Probability</p>
                    <p className="descValue">1/4913</p>
                  </div>
                  <div className="itemDescContainer">
                    <p className="descKey">Supply</p>
                    <p className="descValue">1</p>
                  </div>
                </li>
              })
            }
            </ul>
            <p className="seriesListTxt">Examples of cards are listed above.</p>
            <p className="backgroundTitle" id="DescriptionId">{textConstant.HappyMonkeyBackgroundTitle}</p>
            <p className="backgroundContent" dangerouslySetInnerHTML={{__html: textConstant.HappyMonkeyBackgroundContent}}></p>
            <img src='https://happymonkey.oss-cn-guangzhou.aliyuncs.com/img/introduction.png' alt="" className='introduction-image' />
            <div className="buttonContainer">
              {/* <Button type="primary" className="homeButn" onClick={() => {
                // history.push({pathname: "/"})
                window.open("https://happymonkeymounthuaguo.com")
              }}><div className="arrowLeft" /><div className="arrowLine" />Back to Home</Button> */}
            </div>
          </div>
        </div>
      </div>
      <BuyModal from="HappyMonkeyNft" modal={modal} afterCloseModal={() => { setModal(false) }} />
    </div>
  )
}
export default withRouter(HappyMonkeyPage)