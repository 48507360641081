import { INFURA_ID } from "../constants"
import WalletConnectProvider from "@walletconnect/web3-provider"
import Web3Modal from "web3modal"


const web3Modal = new Web3Modal({
  network: "mainnet", 
  cacheProvider: true, 
  theme: "light",
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: INFURA_ID
      }
    }
  }
})

export default web3Modal