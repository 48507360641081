import React, {
  useState,
  useEffect
} from 'react'
import {
  Modal,
  Button,
  message
} from 'antd'
import { 
  useContractLoader,
  useUserProviderAndSigner
} from "eth-hooks"
import { useContractConfig } from "@contractConfig"
import { Transactor } from "@contractConfig/helpers"
import { gcUseContractReader } from "@tool"
import { useConfigStore } from '@store'
import { 
  imageConstant,
  textConstant,
} from '@json'
import axios from 'axios'
import './styles.less'

const BuyModal = (props) => {
  const { state } = useConfigStore()
  const [modal, setModal] = useState(false)
  const [process, setProcess] = useState(0)
  const [walletData, setWalletData] = useState(null)
  const [drawDatas, setDrawDatas] = useState([])


  const userProviderAndSigner = useUserProviderAndSigner(state.injectedProvider, null)
  const userSigner = userProviderAndSigner.signer
  const contractConfig = useContractConfig()
  const writeContracts = useContractLoader(userSigner, contractConfig, textConstant.ChainId)
  const readContracts = useContractLoader(state.injectedProvider, contractConfig)
  const tx = Transactor(userSigner)

  useEffect(() => {
    setModal(props.modal)
  }, [props.modal])

  useEffect(() => {
    var _drawDatas = []
    textConstant.HappyMonkeySeriesContent.forEach(element => {
      _drawDatas.push(element.url)
    })
    _drawDatas.push(textConstant.HappyMonkeySeriesContent[0].url)
    _drawDatas.push(textConstant.HappyMonkeySeriesContent[1].url)
    if (process === 0 || process === 1) {
      setDrawDatas(_drawDatas) 
    } else if (process === 2) {
      _drawDatas[2] = walletData.image
      setDrawDatas(_drawDatas) 
    }
  }, [process, walletData])

  async function getWallet() {
    try {
      const _balance = await gcUseContractReader(readContracts, "HappyMonkeyNft", "balanceOf", [state.address])
      const balance = !_balance ? 0 : parseInt(_balance.toString())
      if (balance === 0) {
        setWalletData(null)
        setProcess(2)
        setTimeout(function () {
          setProcess(3)
        }, 3000)
      } else {
        const token = await gcUseContractReader(readContracts, "HappyMonkeyNft", "tokenOfOwnerByIndex", [state.address, balance - 1])
        // console.log("token:",token)
        const tokenURI = await gcUseContractReader(readContracts, "HappyMonkeyNft", "tokenURI", [token])
        // console.log("tokenURI:",tokenURI)
        try {
          axios.get(tokenURI).then(function (response) {
            console.log("response:",response)
            if (response.status === 200) {
              const data = response.data
              setWalletData(data)
              setProcess(2)
              setTimeout(function () {
                setProcess(3)
              }, 3000)
            }
          }).catch(function (error) { console.log(error) })
        } catch (error) {}
      }
    } catch (error) {}
  }

  async function onClickcClaimButn() {
    setProcess(1)

    await tx(writeContracts.HappyMonkeyNft.blindBoxRaffle({
      gasLimit: 300000,
      // gasPrice: 1400000000
    }), update => {
      // console.log("update:",update)
      if (update && (update.status === "confirmed" || update.status === 1)) {
        getWallet()
      } else if (update.status === "failed" || update.status === 0) {
        message.error("Mint Failure")
        setProcess(0)
        setModal(false)
      }
    })
  }
  
  return (
    <>
      <Modal centered visible={modal} footer={null} wrapClassName="buyModal" destroyOnClose={true} afterClose={props.afterCloseModal} onCancel={() => {
        setProcess(0)
        setModal(false)
      }}>
        {/* <>
          <div className="boxImageContainer">
            {
              process < 3 && <img src={imageConstant.happyMonkeyBoxAnimation} alt="" className={`boxImage ${process === 1 ? 'boxImageShadeAnimation' : process === 2 ? 'boxImage_narrow_animation' : ''}`} />
            }
            {
              (process === 3 && walletData) && <img src={walletData.image} alt="" className="boxImage boxImageEnlargeAnimation" />
            }
          </div>
          {
            process === 0 && <>
              <p className="price">0 ETH</p>
              <Button type="primary" block className="claimButn" onClick={ onClickcClaimButn }>Claim</Button>
            </>
          }
          {
            process === 1 && <p className="waitTxt">Waiting for Blockchain Confirmation</p>
          }
          {
            process >= 2 && <>
              <p className="openTitle">Congratulations!</p>
              <Button type="primary" block className="claimButn" onClick={() => { 
                setProcess(0)
                setModal(false)
              }}>Done</Button>
            </>
          }
        </> */}
        <>
          <div className="drawCardContainer">
            {
              process === 3 ? <img src={walletData.image} style={{borderRadius: "8px"}} alt="" className="image" />
              : <>
                <img src={imageConstant.drawCard} alt="" className="image" />
                <div className="backgroundColor">
                  <div className="cardListContainer">
                    <ul className={`cardList ${process === 1 ? 'drawingCardAnimation' : process === 2 ? 'drawedCardAnimation' : ''}`}
                        style={{left: process === 1 ? "0" : "-166px"}}>
                    {
                      drawDatas.map((item, index) => {
                        return <li className="cardItem" key={index}>
                          {
                            item.indexOf("http") === 0 ? <img src={item} alt="" className="cardImage" />
                            : <img src={`${imageConstant.happyMonkeyCard}${item}.jpg`} alt="" className="cardImage" />
                          }
                        </li>
                      })
                    }
                    </ul>
                  </div>
                </div>
              </>
            }
          </div>
          {
            process === 0 && <>
              <p className="price">0 ETH</p>
              <Button type="primary" block className="claimButn" onClick={ onClickcClaimButn }>Mint</Button>
            </>
          }
          {
            (process === 1 || process === 2) && <p className="waitTxt">Waiting for Blockchain Confirmation</p>
          }
          {
            process === 3 && <>
              <p className="openTitle">Congratulations!</p>
              <Button type="primary" block className="claimButn" onClick={() => { 
                setProcess(0)
                setModal(false)
              }}>Done</Button>
            </>
          }
        </>
      </Modal>
    </>
  )
}

export default BuyModal