import { useEffect } from 'react'

const Main = (props) => {
  useEffect(() => {
    document.body.scrollIntoView()
  }, [props.location.pathname])
  
  return (
    props.children
  )
}
export default Main