import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom"
import React from "react"
import { Head, Main } from '@component'
import { HappyMonkey, Home2 } from '@src/page'
import "@config/i18n"
import './App.less'

function App() {
  return (
    <Router>
      <Head />
      <Switch>
        <Main>
          <Route exact path="/"><Home2 /></Route>
          <Route path="/happyMonkey"><HappyMonkey /></Route>
        </Main>
      </Switch>
      {/* <Foot /> */}
    </Router>
  )
}

export default App