import React, {
  useState,
  useEffect
} from 'react'
import { withRouter } from "react-router-dom"
import { Button } from 'antd'
import { useContractLoader } from "eth-hooks"
import { useContractConfig } from "@contractConfig"
import { gcUseContractReader } from "@tool"
import { useConfigStore } from '@store'
import { imageConstant } from "@json"
import axios from 'axios'
import './styles.less'

const ProfilePage = ({ history }) => {
  const { state } = useConfigStore()
  const [balance, setBalance] = useState(0)
  const [wallets, setWallets] = useState([])

  const contractConfig = useContractConfig()
  const readContracts = useContractLoader(state.injectedProvider, contractConfig)

  useEffect(() => {
    async function getBalance() {
      try {
        const _newNalance = await gcUseContractReader(readContracts, "HappyMonkeyNft", "balanceOf", [state.address])
        const _balance = !_newNalance ? 0 : parseInt(_newNalance.toString())
        setBalance(_balance)
      } catch (error) {}
    }
    getBalance()
  },[readContracts, state.address])
  
  useEffect(() => {
    var _wallets = []
    for (let index = 0; index < balance; index++) {
      async function getToken() {
        const token = await gcUseContractReader(readContracts, "HappyMonkeyNft", "tokenOfOwnerByIndex", [state.address, index])
        const tokenURI = await gcUseContractReader(readContracts, "HappyMonkeyNft", "tokenURI", [token])
        try {
          axios.get(tokenURI).then(function (response) {
            if (response.status === 200) {
              const data = response.data
              _wallets.push(data)
            }
            if (_wallets.length === balance) {
              setWallets(_wallets)
            }
          }).catch(function (error) {
            console.log(error);
          })
        } catch (error) {}
      }
      getToken()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balance])

  return (
    <div className="profilePage">
      <div className="bannerContainer" style={{backgroundImage: `url(${imageConstant.profileBg})`}}>
        <div className="infoContainer">
        <img src={imageConstant.profile} alt="" className="icon" />
          <p className="address">{state.simpleAddress}</p>
        </div>
      </div>
      <div className="container">
        <p className="sectionTitle">In Your Wallet</p>
        {
          (!!wallets && wallets.length > 0) && <ul className="cardList">
          {
            wallets.map((item, index) => {
              return <li className="cardItem" key={index}>
                <img src={item.image} alt="" className="itemImage" />
                <p className="itemName">{item.name}</p>
              </li>
            })
          }
          </ul>
        }
        {
          (!wallets || wallets.length === 0) && <div className="emptyContainer">
            <img src={imageConstant.profileEmpty} alt="" className="image" />
            <p className="txt">Oops, there's nothing left here.</p>
            <Button type="primary" className="homeButn" onClick={() => {
              history.push({pathname: "/"})
            }}>Back to Home<div className="arrowLine" /><div className="arrowRight" /></Button>
          </div>
        }
      </div>
    </div>
  )
}

export default withRouter(ProfilePage)