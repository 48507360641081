import React from 'react'
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import './styles.less'

const Home2Page = ({ history }) => {
  const { t, i18n } = useTranslation()

  const pcImage = i18n.language === 'en' ? "https://happymonkey.oss-cn-guangzhou.aliyuncs.com/img/pc-en.png" : "https://happymonkey.oss-cn-guangzhou.aliyuncs.com/img/pc-cn.png"
  const mobileImage = i18n.language === 'en' ? "https://happymonkey.oss-cn-guangzhou.aliyuncs.com/img/mobile-en.png" : "https://happymonkey.oss-cn-guangzhou.aliyuncs.com/img/mobile-cn.png"

  return (
    <div className='home-2-page'>
      <img src={pcImage} alt='' className='image pc-image' />
      <img src={mobileImage} alt='' className='image mobile-image' />
      <p className='change-button' onClick={() => { i18n.changeLanguage(i18n.language === 'en' ? 'zh' : 'en') }}>{ t("language") }</p>
      <div className='push-button' onClick={() => history.push("happyMonkey")}></div>
    </div>
  )
}

export default withRouter(Home2Page)