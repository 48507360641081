import imageConstant from './constant/image.json'
import linkConstant from './constant/link.json'
import textConstant from './constant/text.json'

const homeCatalogList = [{
  name: "HAPPY MONKEY",
  pathname: "/collection/happy-monkey",
  url: imageConstant.catalogHappyMonkey,
},{
  name: "More Funs",
  pathname: "are coming.",
  url: imageConstant.catalogEnd,
}]

export {
  imageConstant,
  linkConstant,
  textConstant,
  homeCatalogList
}